<template>
    <div class="payment-form">
        <BaseCloseIcon class="icon" @click="$emit('closePayment')"/>        
        <BaseInput 
            label="Сумма к оплате"
            outlined
            validation="pay"
            :minPay="minPay"
            :maxPay="maxPay"
            @sendData="setOrderAmount($event)" />
        <span class="warning">{{ warning }}</span>
        <div class="payment-way">
            <div :class="{ 'pay-icon': true, 'pay-icon-active': system.active }" v-for="system in paymentSystems" :key="system.id" @click="getPaymentSystem(system.id)">
               <img :src="require(`@/assets/payment/${system.photo}`)" alt="">
            </div>
        </div>
        <BaseButton
            value="оплатить"
            animated
            bbt
            lrl
            :disabled="!isPaymentSystemReady"
            @action="submitForm"
        />
    </div>
</template>

<script>
import paymentData from "@/database/payment.js";
import getPaymentHash from "@/api/getPaymentHash.js";
import { ref, reactive, computed } from "vue"
import { useStore } from "vuex";
export default {
    components: {
    },
    setup () {
        const store = useStore();
        const merchant_id = paymentData.merchant_id;
        const secret_word = paymentData.secret_word;
        const currency = paymentData.currency;
        const minPrice = paymentData.minPrice;
        let order_id = store.state.user.user.id;
        let i = ref(null);
        let order_amount = ref(null);
        const minPay = 75;
        const maxPay = 10000;

        const paymentSystems = reactive([
            {
                name: 'Visa',
                id: 4,
                photo: 'light_small_logo_4.png',
                active: false
            },
            {
                name: 'Mastercard',
                id: 8,
                photo: 'light_small_logo_8.png',
                active: false
            },
            {
                name: 'Yoomoney',
                id: 6,
                photo: 'light_small_logo_6.png',
                active: false
            },
            {
                name: 'Qiwi',
                id: 10,
                photo: 'light_small_logo_10.png',
                active: false
            },
            {
                name: 'Mir',
                id: 12,
                photo: 'light_small_logo_12.png',
                active: false
            },
            {
                name: 'Megafon',
                id: 28,
                photo: 'light_small_logo_28.png',
                active: false
            },
            {
                name: 'OnlineBank',
                id: 13,
                photo: 'light_small_logo_13.png',
                active: false
            },
        ]);

        const getPaymentSystem = (id) => {
            i.value = id;
            paymentSystems.forEach(el => {
                if(el.id === id){
                    el.active = true
                } else {
                    el.active = false
                }
            })
        }

        let warning = ref('');

        const setOrderAmount = (e) => {
            if(e < 75){
                warning.value = 'Минимальная сумма платежа 75 руб'
                order_amount.value = null;
                return false;
            } else if (e > 10000){
                warning.value = 'Максимальная сумма платежа 10000 руб'
                order_amount.value = null;
                return false;
            } else {
                order_amount.value = e;
                warning.value = ''
            }
        }

        let isPaymentSystemReady = computed(() => {
            if(order_amount.value && i.value){
                return true
            } else {
                return false
            }
        })

        const submitForm = async () => {
        
            let id = await getPaymentHash.setOrder({
                massagist_id: order_id,
                amount: order_amount.value,
            }).then(response => response.data.insertId)
                .catch(err => console.log(err))
            
            let sign = await getPaymentHash.getHash({string_to_hash: `${merchant_id}:${order_amount.value}:${secret_word}:${currency}:${id}`})
                    .then(response => response.data)
            
            document.location.replace(`https://pay.freekassa.ru/?m=${merchant_id}&oa=${order_amount.value}&o=${id}&s=${sign}&currency=${currency}&i=${i.value}`);
            


        }
        
        return {
            merchant_id,
            secret_word,
            currency,
            order_amount,
            order_id,
            submitForm,
            setOrderAmount,
            minPrice,
            warning,
            minPay,
            maxPay,
            paymentSystems,
            getPaymentSystem,
            isPaymentSystemReady
        }
    }
}
</script>

<style lang="scss" scoped>
    .payment-form{
        background-color: white;
        width: 320px;
        z-index: 1000;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        .icon{
            position: absolute;
            top: 3px;
            right: 3px;
            cursor: pointer;
        }
        .warning{
            font-size: 0.6em;
            color: red;
            margin-top: 5px;
        }
        .payment-way{
            width: 80%;
            display: flex;
            flex-wrap: wrap;
            margin-top: 15px;
            justify-content: space-around;
            .pay-icon{
                width: 50px;
                height: 30px;
                margin: 10px;
                text-align: center;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .pay-icon-active{
                outline: 1px solid red;
            }
        }
    }
</style>